import apiService from './apiService';

const MICRONUTRIENT_SELF_PHLEBOTOMY_PRODUCT_PRICE = 199;
const AR_PROVIDER_MN_SELF_PHLEBOTOMY_PRICE = 179;
const ageRejuvenationProviderID = 1157;


export const getAllProviderProducts = async (providerId, isSelfPhlebotomy) => {
    try {
        // (1) Fetch the products from the API
        const response = await apiService.get('/products/');

        // if (isSelfPhlebotomy) {
        //     const micronutrientProduct = response.data.results.filter(product => product.code === 'MN')[0];
        //
        //     micronutrientProduct.price = MICRONUTRIENT_SELF_PHLEBOTOMY_PRODUCT_PRICE;
        //
        //     // HARD CODED for AR Provider only
        //     if (providerId === ageRejuvenationProviderID) {
        //         micronutrientProduct.price = AR_PROVIDER_MN_SELF_PHLEBOTOMY_PRICE;
        //     }
        //
        //     const allProducts = response.data.results.filter(product => product.code !== 'MN');
        //     return [...allProducts, micronutrientProduct]
        // }

        // update the products and provide the new product

        // console.log(response.data.results)

        // (2) Prepare the new fake product for better customer experience
        const copyMnTest = response.data.results.filter(product => product.code === 'MN')[0];
        const newUpdatedKit = {
            ...copyMnTest,
            price: MICRONUTRIENT_SELF_PHLEBOTOMY_PRODUCT_PRICE,
            id: 888,
            isSelfPhlebotomy: true,
        }

        // (3) Return the updated product list
        const updatedProductList = [...response.data.results, newUpdatedKit];


        // (4) Exclude some products from the list and return the updated list to components
        const excluded = ["AP", "CE", "RA", "FA", "SK", "RS",];
        return updatedProductList.filter(product => !excluded.includes(product.code));

    } catch (error) {
        // Handle the error appropriately
        console.error('Error fetching products:', error);
        throw new Error('Failed to fetch products. Please try again later.', {originalError: error});
    }
}