import React from 'react';
import styled from "styled-components";
import {Layout} from "antd";

const MainLayoutWrapper = styled(Layout)`

    & * {
        font-family: 'Montserrat', sans-serif;
    }

    @media only screen and (min-width: 575px) {
        margin-left: 260px !important;
    }
`

const BrandBlock = styled.div`
    display: block;
    background: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 10px 10px 15px 1px rgba(69, 90, 100, 0.048);
    margin: 100px 50px 0 50px;

    @media only screen and (max-width: 575px) {
        margin: 5px;
        z-index: 120;
    }
`

const LeftPart = styled.div`
    border-top-left-radius: 5px;
    z-index: 1;
    height: 100%;
    width: 50px;
    background: linear-gradient(0deg, #01a9ac, #01dbdf);
    background-size: 200% 200%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    position: absolute;
    left: 0;
    animation: Gradient 5s ease infinite;


    @media only screen and (max-width: 575px) {
        width: 5px;
    }

    @keyframes Gradient {
        0% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0 50%;
        }
    }
`

const RightPart = styled.div`
    z-index: 2;
    width: 100%;
    position: relative;
    overflow: hidden !important;
    background: transparent;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

    padding: 0 10px 20px 75px;
    border-bottom-left-radius: 5px;

    @media only screen and (max-width: 575px) {
        padding: 0 0 0 10px;
    }
`

const MainContentBlock = ({children}) =>
    <BrandBlock>
        <RightPart>
            <LeftPart/>
            {children}
        </RightPart>
    </BrandBlock>;


export {MainLayoutWrapper, MainContentBlock}