import React from "react";
import {useQuery} from 'react-query';
import {loadKitsForCurrentPatient} from "../../../api/patientService"
import {CloseOutlined} from "@ant-design/icons";
import {Col, Drawer, Row, Descriptions} from "antd";
import KitsTable from "./KitsTable";
import dayjs from "dayjs";

const disableDrawer = (open) => {
    // fix bug on mobile devices with scroll
    if (open) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }
}

const PatientDetails = ({onClose, open, selectedPatient, setSelectedPatient, providerId}) => {

    disableDrawer(open);
    const fetchAllPatientKits = () => loadKitsForCurrentPatient(selectedPatient);
    const {
        data: kitsList,
        isLoading,
        // error, // TODO: Handle error
    } = useQuery(['kits', selectedPatient], fetchAllPatientKits, {
        enabled: !!selectedPatient
    });

    const onCloseHandler = () => {
        setSelectedPatient(null); // Clear the selected user when the drawer is closed
        onClose();
    };

    const renderDrawerContent = (providerId) => selectedPatient && kitsList && !isLoading
        ? <Row gutter={[24, 24]}>
            <Col xl={24}>
                <KitsTable kitsList={kitsList} isLoading={isLoading} providerId={providerId}/>
            </Col>
        </Row>
        : null

    const variable1 = dayjs(selectedPatient?.date_of_birth, 'MM-DD-YYYY').format('YYYY-MM-DD');
    const variable2 = dayjs().year() - dayjs(variable1).year();

    return <Drawer
        closeIcon={<CloseOutlined/>}
        title="Patient Details"
        width={window.innerWidth > 1200 ? '80%' : '92%'}
        closable={window.innerWidth <= 1200}
        onClose={onCloseHandler}
        open={open}
    >


        <Descriptions bordered size={'small'} style={{marginBottom: 30}}>
            <Descriptions.Item label="First Name">{selectedPatient?.first_name}</Descriptions.Item>
            <Descriptions.Item label="Last Name">{selectedPatient?.last_name}</Descriptions.Item>
            <Descriptions.Item label="Age">{variable2}</Descriptions.Item>
            <Descriptions.Item label="Gender">{selectedPatient?.gender}</Descriptions.Item>
            <Descriptions.Item label="Email">{selectedPatient?.email}</Descriptions.Item>
            <Descriptions.Item label="Phone">{selectedPatient?.phone}</Descriptions.Item>
        </Descriptions>


        {renderDrawerContent(providerId)}
    </Drawer>
};


export default PatientDetails;