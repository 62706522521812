/*
* This file it's main router config. All urls and components for this urls, texts for title and others in the future.
* Use this file firstly for creating new page.
* */
import NotFoundPage from "../pages/base/NotFoundPage";
import Information from "../pages/application/Information";
import MyPatients from "../pages/application/MyPatients";
import NewOrder from "../pages/application/NewOrder";
import History from "../pages/application/History";
import Inventory from "../pages/application/Inventory";

import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import ResetPassword from "../pages/auth/ResetPassword";

import {AuthRequired} from "../pages/auth/AuthProvider";

import PaymentSuccessful from "../pages/stripe/PaymentSuccessful";
import PaymentCancel from "../pages/stripe/PaymentCancel";
import NewDirectOrder from "../pages/application/NewDirectOrder";
import PromoCampaignOrder from "../pages/application/PromoCampaignOrder";

const pages = {

    'stripeSuccessPayment': {
        url: '/payment-successful',
        title: 'The payment was successful!',
        jsx: <PaymentSuccessful/>,
    },

    'stripeCancelPayment': {
        url: '/payment-canceled',
        title: 'Payment has been cancelled!',
        jsx: <PaymentCancel/>,
    },

    'login': {
        url: '/login',
        title: 'Login',
        jsx: <Login/>,
    },

    'logout': {
        url: '/logout',
        title: 'Logout',
        jsx: <Logout/>,
    },

    'resetPassword': {
        url: '/reset-password',
        title: 'Reset password',
        jsx: <ResetPassword/>,
    },

    'notFoundPage': {
        url: '*',
        title: 'Page not found. 404',
        jsx: <NotFoundPage/>,
    },

    'information': {
        url: '/my-information',
        title: 'Information',
        jsx: <AuthRequired><Information/></AuthRequired>,
    },

    'myPatients': {
        url: '/my-patients',
        title: 'My Clients',
        jsx: <AuthRequired><MyPatients/></AuthRequired>,
    },

    'newOrder': {
        url: '/new-order',
        title: 'Office Order',
        jsx: <AuthRequired><NewOrder/></AuthRequired>,
    },

    'newDirectOrderToTheCustomer': {
        url: '/new-direct-order',
        title: 'Direct Consumer',
        jsx: <AuthRequired><NewDirectOrder/></AuthRequired>,
    },

    'history': {
        url: '/orders-history',
        title: 'History',
        jsx: <AuthRequired><History/></AuthRequired>,
    },

    'inventory': {
        url: '/',
        title: 'Inventory',
        jsx: <AuthRequired><Inventory/></AuthRequired>,
    },

    'promoCampaignOrder': {
        url: '/promo-campaign-order',
        title: 'Deals',
        jsx: <AuthRequired><PromoCampaignOrder/></AuthRequired>,
    },

};

export default pages;