import React, {useContext, useState} from "react";
import {useLocation} from 'react-router-dom';
import {NavLink} from "react-router-dom";
import pages from "./routing/config";
import {AuthContext} from "./pages/auth/AuthContext";

import {IdcardOutlined, ProfileOutlined, TeamOutlined, LogoutOutlined} from "@ant-design/icons";
import {Layout, Col, Row, Divider, Tag, Typography} from "antd";


import {LOGO} from './assets/images'
import {Nav, MobileNav, MobileMenuDrawer} from "./components/Navigation";
import SiderLogo, {LogoImg} from "./components/SiderLogo";
import {useNavigate} from "react-router-dom";

const {Sider} = Layout
const {
    information,
    myPatients,
    newOrder,
    newDirectOrderToTheCustomer,
    history,
    inventory,
    logout,
    promoCampaignOrder
} = pages;


const getItem = (label, key, icon, children) => ({key, icon, children, label,});

const provider_menu_items = [/* Menu builder */

    getItem('Orders', null, <ProfileOutlined style={{fontSize: '130%', marginRight: 20}}/>, [
        getItem(promoCampaignOrder.title, promoCampaignOrder.url),
        getItem(newDirectOrderToTheCustomer.title, newDirectOrderToTheCustomer.url),
        getItem(newOrder.title, newOrder.url),
        getItem(history.title, history.url),
        getItem(inventory.title, inventory.url),
    ]),
    getItem(myPatients.title, myPatients.url, <TeamOutlined style={{fontSize: '130%', marginRight: 20}}/>),
    getItem(information.title, information.url, <IdcardOutlined style={{fontSize: '130%', marginRight: 20}}/>),

    getItem(logout.title, logout.url, <LogoutOutlined style={{fontSize: '130%', marginRight: 20}}/>),
];


const DesktopNavigation = () => {
    /* Main navigation component. Handle all main pages in application. Extends ant `Menu` component. */

    const {authContext} = useContext(AuthContext);

    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();


    const mainMenuHandler = (args) => {
        /* Handle all clicks and navigate to needed page */
        const {keyPath} = args;
        const [url] = keyPath;
        navigate(url);
    }

    return <Row>
        <Col xs={0} lg={24}>
            <Sider
                width={'260px'}

                style={{
                    background: '#11242f',
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,

                }}
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >

                <NavLink to={pages.inventory.url}>
                    <SiderLogo
                        image_source={LOGO}
                    />
                </NavLink>

                <Nav
                    theme="dark"
                    selectedKeys={[location.pathname]}
                    mode="inline"
                    items={provider_menu_items}
                    onClick={mainMenuHandler}
                />

                <Divider/>

                {/*<p style={{color: "white", fontSize: 8, padding: 20}}>*/}
                {/*    <pre>*/}
                {/*   {JSON.stringify(authContext?.providerData, null, 2)}*/}
                {/*    </pre>*/}
                {/*</p>*/}

                <Typography.Paragraph style={{color: "white", textAlign: "center"}}>
                    {authContext?.providerData?.doctor_npi
                        ? <Tag color="blue" style={{fontSize: 8,}}>NPI: {authContext?.providerData?.doctor_npi}</Tag>
                        : <Tag color="blue" style={{fontSize: 8,}}>PHYSICIAN NETWORK</Tag>
                    }
                </Typography.Paragraph>

            </Sider>
        </Col>
    </Row>
}


const MobileNavigation = ({open, onClose}) => {
    /* Mobile navigation component.
    Handle all main pages in application.
    Extends from `ant `Drawer` and `Menu` components. */
    const navigate = useNavigate();


    const mainMenuHandler = (args) => {
        /* Handle all clicks and navigate to needed page */
        const {keyPath} = args; // getting current `key` attr in clicked Menu Item
        const [url] = keyPath;

        onClose();  // close MobileMenu Drawer
        navigate(url);  // use `key` attr value for routing
    }

    // fix bug on mobile devices with scroll
    if (open) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }


    return <MobileMenuDrawer
        width={'82%'} placement="right" title={null} closable={false}
        onClose={onClose} open={open} destroyOnClose={true} maskClosable={true}
        zIndex={1000} getContainer={false}
    >

        {/*Drawer value */}
        <MobileNav theme="dark" mode="inline" items={provider_menu_items} onClick={mainMenuHandler}/>
        <LogoImg src={LOGO}/>

    </MobileMenuDrawer>
}


export {
    DesktopNavigation, MobileNavigation,
}