import React, {useState, useEffect} from "react";
import {getReportLink} from "../../../api/patientService";
import useStore from "../../store";
import dayjs from "dayjs";
import styled from "styled-components";
import {NavLink} from "react-router-dom";

import {
    FilePdfOutlined,
    LinkOutlined,
    ProfileOutlined,
    CloseOutlined,
    WarningOutlined,
    ShopOutlined
} from "@ant-design/icons";
import {Button, Space, Modal, Alert, Tooltip, Row, Col, Typography, Spin} from "antd";

import {FinalizedOrdersTable} from "../Inventory/styled-components/FinalizedTable";
import VitaminLabsDrawer from "./VitaminlabsDrawer";

const ElaborateReportModal = styled(Modal)`
    & .ant-modal-body {
        height: ${props => props.isFullHeight ? '100vh' : 'auto'};
    }

    .ant-modal-content {
        padding: 0 !important;
    }
`

const disableDrawer = (open) => {
    // fix bug on mobile devices with scroll
    if (open) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }
}


const KitsTable = ({kitsList, isLoading, providerId}) => {
    const pagination = {position: ["bottom"],}

    // table column settings
    const columns = [

        {
            title: 'Barcode', dataIndex: 'barcode', responsive: ['xs', 'lg'],
        },

        {
            title: 'Test', dataIndex: 'test', responsive: ['lg'],
        },

        {
            title: 'Status', dataIndex: 'status', responsive: ['xs', 'lg'],
        },

        {
            title: 'Activation Date',
            dataIndex: 'dateActivated',
            render: date => <>{dayjs(date).format("YYYY-MM-DD")}</>,
            responsive: ['lg',],
        },

        {
            title: 'Results', dataIndex: 'created', render: (finalDate, currentKit) =>

                <Space key={finalDate}>
                    {currentKit.status === 'LabDecline'

                        ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                            <Button
                                danger
                                color={'danger'}
                                icon={<WarningOutlined/>}
                                onClick={() => showDeclinedModal(currentKit)}
                            />
                        </Tooltip>

                        : currentKit.status === 'MDDecline'

                            ? <Tooltip placement="left" title={'Reason explanation'} zIndex={10}>
                                <Button
                                    danger
                                    icon={<WarningOutlined/>}
                                    onClick={() => showDeclinedModal(currentKit)}
                                />
                            </Tooltip>

                            : currentKit.test === "MN" && currentKit.status === 'Final'

                                ? <Space>

                                    <Tooltip placement="left" title={'Vitamins list'}>
                                        <Button icon={<ProfileOutlined/>}
                                                onClick={() => showDrawerHandler(currentKit)}/>
                                    </Tooltip>

                                    <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                        <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>
                                    </Tooltip>

                                    <Tooltip placement="right" title={'Personal report'} zIndex={10}>
                                        <Button
                                            icon={<FilePdfOutlined/>}
                                            onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                        />
                                    </Tooltip>

                                </Space>

                                : ["FS", "FE"].includes(currentKit?.test) && currentKit.status === 'Final'

                                    ? <Space>
                                        <Tooltip placement="left" title={'General info'} zIndex={10}>
                                            <Button
                                                icon={<FilePdfOutlined/>}
                                                onClick={openGeneralPdf}
                                            />
                                        </Tooltip>

                                        <Tooltip placement="right" title={'Personal report'} zIndex={10}>
                                            <Button
                                                icon={<FilePdfOutlined/>}
                                                onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                            />
                                        </Tooltip>
                                    </Space>

                                    : ["CC"].includes(currentKit?.test) && currentKit.status === 'Final' && currentKit?.finalDate >= '2023-12-29'

                                        ? <Space>
                                            <Tooltip placement="right" title={'Personal report'}
                                                     zIndex={10}>
                                                <Button
                                                    icon={<FilePdfOutlined/>}
                                                    onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                                />
                                            </Tooltip>
                                        </Space>

                                        : ["WM", "CE", "AP"].includes(currentKit?.test) && currentKit.status === 'Final'

                                            ? <Space>
                                                <Tooltip placement="right" title={'Personal report'}
                                                         zIndex={10}>
                                                    <Button
                                                        icon={<FilePdfOutlined/>}
                                                        onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}
                                                    />
                                                </Tooltip>
                                            </Space>

                                            : ["SK"].includes(currentKit?.test) && currentKit.status === 'Final'

                                                ? <Space>
                                                    <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                                        <Button
                                                            icon={<LinkOutlined/>}
                                                            onClick={() => showModal(currentKit)}
                                                        />
                                                    </Tooltip>

                                                    <Tooltip placement="top" title={'Go to portal'}
                                                             zIndex={10}>
                                                        <NavLink
                                                            to={'https://app.essentialapothecaryrx.com/login'}
                                                            target={'_blank'}
                                                        >
                                                            <Button icon={<ShopOutlined/>}
                                                                    type={'dashed'}/>
                                                        </NavLink>
                                                    </Tooltip>
                                                </Space>

                                                : currentKit.test !== "MN" && currentKit.test !== "FE" && currentKit.status === 'Final' ?

                                                    <Tooltip placement="left" title={'Full report'} zIndex={10}>
                                                        <Button icon={<LinkOutlined/>}
                                                                onClick={() => showModal(currentKit)}/>
                                                    </Tooltip> : <span>Pending</span>


                    }
                </Space>, responsive: ['xs', 'md',],
        },

    ];

    const providerData = useStore(state => state.providerData);

    const openGeneralPdf = () => {
        /* Default PDF-file with explanations for FS and FE test-kits */

        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = '/static_files/56%20pages%20report.pdf';
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    };
    const openPersonalPdf = async (kitID, kitBarcode) => {
        /* Personal PDF-file with explanations for FS and FE test-kits.
           Works with FE, FS, MN test kits.
           Each barcode has its own PDF-file with unique content.
           PDF-url formula: f"{file_name_without_extension}_{kit.id}.pdf" */
        const storageURL = "https://efunctional-static-server.s3.us-east-1.amazonaws.com";
        const fileName = `/mediafiles/patients-pdf-reports/${kitBarcode}_${kitID}.pdf`;
        const finalUrl = storageURL + fileName;

        window.open(finalUrl);
    }

    // Declined kit  modal
    const [openDeclinedModal, setOpenDeclinedModal] = useState(false);

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);

    const showDeclinedModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpenDeclinedModal(true);
    };

    // const handleDeclinedModalOk = () => {
    //     handleDeclinedModalCancel();
    // };

    const handleDeclinedModalCancel = () => {
        setOpenDeclinedModal(false);
    };


    // modal and iframe stuf
    const [open, setOpen] = useState(false);
    const [selectedKIT, setSelectedKIT] = useState(null);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [iframeLink, setIframeLink] = useState(null);
    const [reportError, setReportError] = useState(null);

    const showDrawerHandler = currentKIT => {
        setSelectedKIT(currentKIT);
        setIsDrawerOpened(true);
    }

    const showModal = (currentKit) => {
        setSelectedKIT({...currentKit})
        setOpen(true);
    };

    const handleOk = () => {
        handleCancel();
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
        setSelectedKIT(null);
        setIframeLink(null);
        setReportError(null);
    };

    useEffect(() => {
        if (selectedKIT && open) {
            (async () => {
                try {
                    setIsReportLoading(true);
                    const response = await getReportLink(selectedKIT);
                    setIframeLink(response)
                } catch (error) {

                    if (error.hasOwnProperty('response')) {
                        setReportError(error.response.data.error);
                    }

                    if (error.hasOwnProperty('message')) {
                        setReportError(error.message);
                    }

                } finally {
                    setIsReportLoading(false);
                }
            })();
        }
    }, [open])

    return <>

        <VitaminLabsDrawer
            selectedKIT={selectedKIT}
            setSelectedKIT={setSelectedKIT}
            isDrawerOpened={isDrawerOpened}
            setIsDrawerOpened={setIsDrawerOpened}
            providerId={providerId}
        />


        {/*Elaborate Report Here   ***--- START ---*** */}
        <ElaborateReportModal
            isFullHeight={iframeLink && !reportError}
            centered={true}
            destroyOnClose={true}
            closable={true}
            width={(reportError && !iframeLink) || (!reportError && !iframeLink) ? '20%' : '95%'}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
        >

            {reportError ? <Spin spinning={isReportLoading}>
                <Row justify={"center"} gutter={[24, 24]} style={{padding: "20px"}}>
                    <Col>
                        <Alert style={{margin: "30px 0 0 0", textAlign: "center"}} message={reportError}/>
                    </Col>
                </Row>
            </Spin> : null}

            {iframeLink
                ? <iframe
                    width={'100%'}
                    height={"100%"}
                    loading={isReportLoading}
                    title={'Elaborate report'}
                    src={iframeLink}
                />
                : null
            }

            <Row justify={"center"} style={{padding: "0 0 20px 0"}}>
                <Col>
                    <Button
                        icon={reportError ? null : <CloseOutlined/>}
                        size={'small'}
                        type={reportError ? 'primary' : 'default'}
                        onClick={handleCancel}
                    >
                        {reportError
                            ? 'Okay'
                            : 'Close report'
                        }
                    </Button>
                </Col>
            </Row>

        </ElaborateReportModal>
        {/*Elaborate Report Here   ***--- END ---*** */}

        <FinalizedOrdersTable
            title={() => <Typography.Title level={5}>Results</Typography.Title>}
            rowKey={'patient_details.id'}
            loading={isLoading}
            sticky={true}
            size={'small'}
            // columns={providerData?.be_able_to_see_patients_results ? columns : columns.slice(0, 4)}
            columns={providerData?.be_able_to_see_patients_results ? columns : columns.slice(0, 4).concat({
                title: 'Vitaminlabs', dataIndex: 'created', render: (finalDate, currentKit) =>

                    <Space key={finalDate}>


                        {currentKit.test === "MN" && currentKit.status === 'Final'

                            ? <Space>

                                <Tooltip placement="left" title={'VL new order'}>
                                    {/*<Button icon={<ProfileOutlined/>} onClick={() => showDrawerHandler(currentKit)}/>*/}

                                    <Button
                                        size={'small'}
                                        icon={<ProfileOutlined/>}
                                        onClick={() => showDrawerHandler(currentKit)}
                                    >
                                        New order
                                    </Button>
                                </Tooltip>

                                {/*<Tooltip placement="left" title={'Full report'} zIndex={10}>*/}
                                {/*    <Button icon={<LinkOutlined/>} onClick={() => showModal(currentKit)}/>*/}
                                {/*</Tooltip>*/}

                                {/*<Tooltip placement="right" title={'Personal report'} zIndex={10}>*/}
                                {/*    <Button*/}
                                {/*        icon={<FilePdfOutlined/>}*/}
                                {/*        onClick={() => openPersonalPdf(currentKit.id, currentKit.barcode)}*/}
                                {/*    />*/}
                                {/*</Tooltip>*/}

                            </Space>

                            : null}
                    </Space>, responsive: ['xs', 'md',],
            })}
            dataSource={kitsList}
            // pagination={pagination}
            pagination={false}
            scroll={false}
        />

    </>
}

export default KitsTable;