import apiService from "./apiService";
import {loadStripe} from '@stripe/stripe-js';

// DEV key
// const stripePromise = loadStripe('pk_test_51NQX1MKbmzi38XjIgKLJeHOgtR8Sg6LKQaJxYTDmvC20zoUuRdBhDueZhdrJneVElw8qlGP2jKfCWqj8ioOoAWFY00fbuczKB2');

// LIVE key
const stripePromise = loadStripe('pk_live_51NQX1MKbmzi38XjIxakuqR9kjehxvOlJOnOsPNPqd9fvgkIbtqexLU11mjhUUkVP4LExn0FLZFTffkLKHEnUX0Iy00q7jY3QRr');


export const createStripeSession = async (orderData) => {
    try {
        // Using Axios for the POST request
        const response = await apiService.post(`/stripe/checkout-provider-order/`, orderData);
        const data = response.data;

        if (data.error) {
            console.error(data.error);
            return;
        }

        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({sessionId: data.id});

        if (result.error) {
            console.error(result.error.message);
        }
    } catch (error) {
        console.error('Error creating Stripe session:', error);
    }
}


export const createStripeSessionForDirectToConsumerOrder = async orderData => {
    try {
        const response = await apiService.post(`/stripe/checkout-direct-provider-order/`, orderData);

        const data = response.data;
        if (data.error) {
            console.error(data.error);
            throw new Error('Failed to create Stripe session: ' + data.error);
        }

        const stripe = await stripePromise;
        if (!stripe) {
            throw new Error('Stripe failed to initialize');
        }

        const result = await stripe.redirectToCheckout({sessionId: data.id});

        if (result.error) {
            console.error(result.error.message);
            throw new Error('Stripe checkout failed: ' + result.error.message);
        }
    } catch (error) {
        console.error(error);
        throw new Error('An error occurred while creating the Stripe session: ' + error.message);
    }
}