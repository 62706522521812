import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";

import {ConfigProvider} from 'antd';


/*-----------------------------------------*/
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import 'antd/dist/reset.css';
/*-----------------------------------------*/
import {AuthProvider} from "./entrypoint/pages/auth/AuthProvider";
import Root from "./entrypoint/routing/router";

import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<React.StrictMode>

    <QueryClientProvider client={queryClient}>
        <ConfigProvider theme={{token: {colorPrimary: '#01a9ac'}}}>
            <AuthProvider>
                <BrowserRouter basename={'/'}>
                    <Root/>
                </BrowserRouter>
            </AuthProvider>
        </ConfigProvider>
    </QueryClientProvider>

</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
