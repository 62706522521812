import React, {useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import pages from "./config";

import MainLayout from "../MainLayout";


const Root = () => {
    const location = useLocation();

    const {
        stripeCancelPayment,
        stripeSuccessPayment,
        information,
        myPatients,
        newOrder,
        newDirectOrderToTheCustomer,
        history,
        inventory,
        notFoundPage,
        login,
        logout,
        resetPassword,
        promoCampaignOrder
    } = pages;

    useEffect(() => {
        try {
            const page = Object.values(pages).find(p => p.hasOwnProperty('title') && p.url === location.pathname);
            document.title = `${page.title} | Provider portal | EFunctional`;
        } catch (err) {
            // Do nothing.
        }
    }, [location]);

    return <Routes>
        <Route element={<MainLayout/>}>
            {/*Stripe statuses pages*/}
            <Route path={stripeCancelPayment.url} element={stripeCancelPayment.jsx}/>
            <Route path={stripeSuccessPayment.url} element={stripeSuccessPayment.jsx}/>
            {/*Stripe statuses pages*/}

            {/*Basic pages*/}
            <Route path={notFoundPage.url} element={notFoundPage.jsx}/>
            <Route path={login.url} element={login.jsx}/>
            <Route path={logout.url} element={logout.jsx}/>
            <Route path={resetPassword.url} element={resetPassword.jsx}/>
            {/*Basic pages*/}

            {/* Auth required pages */}
            <Route path={information.url} element={information.jsx}/>
            <Route path={myPatients.url} element={myPatients.jsx}/>
            <Route path={newOrder.url} element={newOrder.jsx}/>
            <Route path={newDirectOrderToTheCustomer.url} element={newDirectOrderToTheCustomer.jsx}/>
            <Route path={history.url} element={history.jsx}/>
            <Route path={inventory.url} element={inventory.jsx}/>
            <Route path={promoCampaignOrder.url} element={promoCampaignOrder.jsx}/>
            {/* Auth required pages */}
        </Route>
    </Routes>;
}

export default Root;