import {Button, Col, Form, Input, message, Row, Spin} from "antd";
import React, {useContext, useEffect, useState} from "react";
import restAPI from "../../../features/restAPI";
import {AuthContext} from "../../auth/AuthContext";
import {LoadingOutlined} from "@ant-design/icons";


const ChangePassword = () => {

    const {authContext} = useContext(AuthContext); // auth data from current session
    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const submitForm = async (formData) => {

        try {
            setIsLoading(true);

            const {new_password, new_password2} = formData;

            if (new_password !== new_password2) {
                throw new Error("New passwords didn't match.");
            }

            await restAPI.post(`/change-password/`, formData, authConfig);

            form.resetFields();

            message.success({
                content: `Your password changed!`, duration: 3,
            });

        } catch (error) {

            // TODO show errors
            console.log(error);

            message.error({
                content: 'We have an errors!', duration: 3,
            });

        } finally {
            setIsLoading(false);
        }
    };


    const triggerSubmitHandler = () => form.submit();

    useEffect(() => {
        form.setFieldsValue({
            ...authContext?.providerData
        });
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row style={{padding: "0 10px"}}>

            <Col xs={24} lg={24} style={{minHeight: '60vh'}}>

                <Form
                    layout="vertical"
                    size={'middle'}
                    onFinish={submitForm}
                    form={form}
                    autoComplete="off"
                >

                    <Row gutter={[24, 24]}>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'Old password'}
                                name="old_password"
                                rules={[{required: true, message: 'Please input your old password!'}]}
                            >
                                <Input.Password placeholder="Old password"/>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row gutter={[24, 24]}>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'New password'}
                                name="new_password"
                                rules={[
                                    {required: true, message: 'Please input new password!',},
                                    {required: true, min: 8, message: 'Password too short!'}
                                ]}
                            >
                                <Input.Password placeholder="New password"/>
                            </Form.Item>
                        </Col>

                    </Row>


                    <Row gutter={[24, 24]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label={'Repeat new password'}
                                name="new_password2"
                                rules={[
                                    {required: true, message: 'Please repeat new password!'},
                                    {required: true, min: 8, message: 'Password too short!'}
                                ]}
                            >
                                <Input.Password placeholder="Repeat new password"/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Button
                                style={{marginBottom: 50,}}
                                type="primary"
                                loading={false}
                                onClick={triggerSubmitHandler}
                            >
                                Change password
                            </Button>
                        </Col>

                    </Row>

                </Form>

            </Col>
        </Row>
    </Spin>
}


export default ChangePassword;