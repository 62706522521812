import React, {useState} from "react";
import pages from "../../../routing/config";

import MainPageTitle from "../../../components/MainPageTitle";

import MN from '../../../assets/productImages/mn.webp';
import FS from '../../../assets/productImages/fs.webp';
import FE from '../../../assets/productImages/fe.webp';
import RS from '../../../assets/productImages/rs.webp';
import ST from '../../../assets/productImages/st.webp';
import CC from '../../../assets/productImages/cc.webp';


import styled from "styled-components";

import {useQuery} from 'react-query';
import {getAllProviderProducts} from '../../../api/productService';
import {createStripeSession} from '../../../api/createStripeSession';
import {createNewSupplyOrder} from '../../../api/orderService';
import useStore from "../../store";


import {
    LoadingOutlined,
    ShoppingCartOutlined,
    DeleteOutlined,
    DoubleRightOutlined,
    DollarOutlined,
} from "@ant-design/icons";
import {Col, Row, Card, Skeleton, Button, Space, Spin, Typography, Divider, Select, Tag, message} from "antd";
import PromoOffer from "./PromoOffer";

const {Meta} = Card;

const productImages = {
    'MN': MN,
    'FS': FS,
    'FE': FE,
    'RS': RS,
    'ST': ST,
    'CC': CC,
}

const ShopCard = styled(Card)`
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    width: 100%;
    border: none;

    & .ant-card-cover {
        overflow: hidden;
    }

    & .ant-card-cover img {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
    }

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    & * {
        font-family: Montserrat, sans-serif;
        outline-color: #01a9ac;
    }

    & .ant-card-head-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 1px;
    }

    & .shopping-button {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
    }

    & .ant-card-cover {
        width: 80%;
        margin: 0 auto;
        transition: 0.1s;
    }

    & .ant-card-head-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1px;
        color: #01a9ac;
    }
`


const promoCampaignOfferData = [
    // {
    //     id: 1,
    //     title: 'Promo Offer 1',
    //     totalQuantity: 9,
    //     minimumQuantity: null,
    //     productList: [
    //         {
    //             productId: 9,
    //             price: 199,
    //             name: 'Genetic Weight Loss',
    //             quantity: 3,
    //             url: 'https://efunctional.com/wm-kit/'
    //         },
    //         {
    //             productId: 1,
    //             price: 249,
    //             name: 'MicroNutrient',
    //             quantity: 3,
    //             url: 'https://efunctional.com/micronutrient-test-kit/',
    //         },
    //         {
    //             productId: 3,
    //             price: 149,
    //             name: 'Food Sensitivity - 108 foods',
    //             quantity: 3,
    //             url: 'https://efunctional.com/food-sensitivity-test-kit/',
    //         }
    //     ],
    //     investmentText: '$995',
    //     wholesaleText: '$1,800',
    //     totalRentalSales: '$2,540',
    //     yourProfit: '$1,545',
    //     details: [
    //         '*64% profit margin from initial investment',
    //         '*must purchase a total of 9 kits, quantities of each are adjustable',
    //     ]
    // },
    //
    // {
    //     id: 2,
    //     title: 'Promo Offer 2',
    //     totalQuantity: 9,
    //     minimumQuantity: null,
    //     productList: [
    //         {
    //             productId: 9,
    //             price: 199,
    //             name: 'Genetic Weight Loss',
    //             quantity: 3,
    //             url: 'https://efunctional.com/wm-kit/'
    //         },
    //         {
    //             productId: 1,
    //             price: 249,
    //             name: 'Micronutrient Test',
    //             quantity: 3,
    //             url: 'https://efunctional.com/micronutrient-test-kit/',
    //         },
    //         {
    //             productId: 6,
    //             price: 199,
    //             name: ' Food Sensitivity - 216 foods',
    //             quantity: 3,
    //             url: 'https://efunctional.com/food-sensitivity-expanded-test-kit/',
    //         }
    //     ],
    //     investmentText: '$1,150',
    //     wholesaleText: '$1,940',
    //     totalRentalSales: '$2,850',
    //     yourProfit: '$1,700',
    //     details: [
    //         '*67% profit margin from initial investment',
    //         '*must purchase a total of 9 kits, quantities of each are adjustable',
    //     ]
    // },

    {
        id: 3,
        title: 'Promo Offer',
        totalQuantity: null,
        minimumQuantity: 5,
        productList: [

            {
                productId: 1,
                name: 'Micronutrient Test - Mobile Phleb.',
                quantity: 0,
                url: 'https://efunctional.com/micronutrient-test-kit/',
                price: 249,
                directToConsumerPrice: 349,
            },

            {
                productId: 888,
                name: 'Micronutrient Test - Self Phleb.',
                quantity: 0,
                url: 'https://efunctional.com/micronutrient-test-kit/',
                price: 199,
                directToConsumerPrice: 349,
            },

            {
                productId: 2,
                name: 'Sexual Health',
                quantity: 0,
                url: 'https://www.efunctional.com/std-test-kit/',
                price: 99,
                directToConsumerPrice: 129,
            },

            {
                productId: 3,
                name: 'Food Sensitivity - 108 foods',
                quantity: 0,
                url: 'https://efunctional.com/food-sensitivity-expanded-test-kit/',
                price: 149,
                directToConsumerPrice: 199,
            },

            {
                productId: 6,
                name: ' Food Sensitivity - 216 foods',
                quantity: 0,
                url: 'https://efunctional.com/food-sensitivity-expanded-test-kit/',
                price: 199,
                directToConsumerPrice: 299,
            },

            {
                productId: 4,
                name: 'Colon Cancer Screening Test',
                quantity: 0,
                url: 'https://www.efunctional.com/colon-cancer-test-kit/',
                price: 49,
                directToConsumerPrice: 99,
            },


            {
                productId: 9,
                name: 'Genetic Weight Loss',
                quantity: 0,
                url: 'https://efunctional.com/wm-kit/',
                price: 199,
                directToConsumerPrice: 299,
            },
        ],
        investmentText: '$1,150',
        wholesaleText: '$1,940',
        totalRentalSales: '$2,850',
        yourProfit: '$1,700',
        details: [
            '* 5 Kit Minimum Purchase',
            '* Existing Accounts 25% off wholesale during first week of new test kit release',
            '* New Accounts 25% off wholesale during first week of partnership',
        ]
    },

]

const PromoCampaignOrder = () => {
    const {promoCampaignOrder} = pages;
    const providerData = useStore(state => state.providerData);
    const {id: providerId, is_self_phlebotomy: isSelfPhlebotomy} = providerData;

    const [shoppingCart, setShoppingCart] = useState([]);
    const [locationsList, setLocationsList] = useState(providerData.locations);

    const locationListOptions = locationsList.map(location => ({
        label: `${location?.state}, ${location?.city}, ${location?.postcode}, ${location?.address_1}  ${location?.address_2 || ''}`,
        value: location.id
    }))

    const [chosenLocation, setChosenLocation] = useState(null);

    const fetchProducts = () => getAllProviderProducts(providerId, isSelfPhlebotomy);
    const {
        data: productList,
        isLoading,
        // error
    } = useQuery(['productList', providerId, isSelfPhlebotomy], fetchProducts);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const onSubmitOfNewSupplyOrder = async () => {
        try {

            // check shopping cart, if - 0 -> stop
            if (shoppingCart.length === 0) {
                return false;
            }

            // Prepare cart data for backend saving
            const cart = shoppingCart.map(
                p => ({product: parseInt(p.id), quantity: p.qty})
            );

            // Save needed address data fro current order
            const currentLocation = locationsList.find(
                location => location.id === chosenLocation
            );

            const orderData = {
                cart: [...cart],  // all products with qty
                provider: parseInt(providerData?.id),
                provider_location: parseInt(chosenLocation),
                clientID: providerData?.id,
                name: providerData?.name,

                email: currentLocation?.location_manager_contact_email || providerData?.email,
                phone: currentLocation?.location_manager_contact_phone || providerData?.phone,
                firstName: currentLocation?.location_manager_first_name || providerData?.name,
                lastName: currentLocation?.location_manager_last_name || providerData?.id,

                address_1: currentLocation?.address_1,
                address_2: currentLocation?.address_2 || '',
                city: currentLocation?.city,
                state: currentLocation?.state,
                postcode: currentLocation?.postcode,

                isExpeditedShipping: false,
            };

            try {
                await createNewSupplyOrder(orderData);

                // If the function executes successfully, the cart will be reset and a success message will be shown.
                resetCart();

                message?.success({
                    content: 'Your order was successfully generated.', duration: 3,
                });
            } catch (error) {
                // Handle the error here. For example, you can log the error or show an error message.
                console.error(error);
                message?.error({
                    content: 'An error occurred while generating your order.', duration: 3,
                });
            }

        } catch (error) {
            console.log(error);
            if (error?.response?.data) {
                message?.error({
                    content: error?.response?.data, duration: 3,
                });
            }
            if (error?.messages) {
                message?.error({
                    content: error?.messages, duration: 3,
                });
            }
        }
    }

    const onSubmitPayNowOrder = async () => {

        // check shopping cart, if - 0 -> stop
        if (shoppingCart.length === 0) {
            return false;
        }

        try {
            setIsClicked(true);
            setIsSubmitting(true);

            // Prepare cart data for request
            const cart = shoppingCart.map(p => ({product: parseInt(p.id), quantity: p.qty, price: p.price}));

            // Save needed address data fro current order
            const currentLocation = locationsList.find(
                location => location.id === chosenLocation
            );

            const orderData = {
                provider_location: parseInt(chosenLocation),
                provider: parseInt(providerId),

                email: currentLocation?.location_manager_contact_email || providerData.email,
                phone: currentLocation?.location_manager_contact_phone || providerData.phone,
                firstName: currentLocation?.location_manager_first_name || providerData.name,
                lastName: currentLocation?.location_manager_last_name || providerData.id,
                address_1: currentLocation?.address_1,
                address_2: currentLocation?.address_2 || '',
                city: currentLocation?.city,
                state: currentLocation?.state,
                postcode: currentLocation?.postcode,

                cart: JSON.stringify([...cart]),

                name: "Provider Order",
                price: shoppingCart.reduce((a, c) => a + c.qty * c.price, 0) * 100,
                // price: 100,
                quantity: 1,  // means 1 order with one shopping cart
            };


            await createStripeSession(orderData);
        } catch (error) {
            console.log(error)
        } finally {
            setIsSubmitting(false);
        }
    }

    const addToCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist && exist.qty < 3) {
            setShoppingCart(shoppingCart.map(x => x.id === exist.id ? {...exist, qty: exist.qty + 1} : x));
        }

        if (!exist) {
            setShoppingCart([...shoppingCart, {...product, qty: 1}]);
        }
    }

    const removeFromCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);
        if (exist.qty === 1) {
            setShoppingCart(shoppingCart.filter((x) => x.id !== product.id));
        } else {
            setShoppingCart(shoppingCart.map((x) => x.id === product.id ? {...exist, qty: exist.qty - 1} : x));
        }
    }

    const resetCart = () => {
        setShoppingCart([]);
        setChosenLocation(null);
    }

    return <Spin
        tip="Loading..."
        spinning={isLoading || isClicked || isSubmitting}
        indicator={<LoadingOutlined size={'large'}/>}
    >

        <Row gutter={[24, 24]}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>
                    {promoCampaignOrder.title}
                </MainPageTitle>
            </Col>

            <Col xs={24} lg={24} xl={24}>
                {promoCampaignOfferData.map((offer, index) => <PromoOffer
                    key={index}
                    id={offer.id}
                    title={offer.title}
                    productList={offer.productList}
                    investmentText={offer.investmentText}
                    wholesaleText={offer.wholesaleText}
                    totalRentalSales={offer.totalRentalSales}
                    yourProfit={offer.yourProfit}
                    details={offer.details}
                    totalQuantity={offer.totalQuantity}
                    minimumQuantity={offer.minimumQuantity}
                />)}
            </Col>

        </Row>
    </Spin>
}

export default PromoCampaignOrder;