import React from "react";
import {Card, Typography, Row, Col, Tag, Button, Space} from "antd";
import {NavLink} from "react-router-dom";
import PromoTimer from "./PromoTimer";
import OrderConfirmationModal from "./OrderConfirmationModal";


const PromoOffer = ({
                        id,
                        title,
                        productList,
                        investmentText,
                        wholesaleText,
                        totalRentalSales,
                        yourProfit,
                        details,
                        totalQuantity,
                        minimumQuantity,
                    }) => {

    if (id === 3) {
        return <>
            <Card style={{marginBottom: 20, background: "rgba(113,202,243,0.03)"}}>
                <Row gutter={[24, 24]}>

                    <Col lg={10}>
                        <Typography.Title level={4}>{title}</Typography.Title>
                    </Col>

                    <Col lg={14}>
                        <PromoTimer/>
                    </Col>


                    <Col lg={24}>
                        <Row gutter={[24, 24]}>
                            {productList.map(product => {
                                return <Col lg={8}>
                                    <Card key={product.id} bordered={false}>
                                        <Row gutter={[0, 8]}>
                                            <Col lg={24}>
                                                <Typography.Text>
                                                    <Tag bordered={false}>Product name:</Tag>
                                                    <strong>{product.name}</strong>
                                                </Typography.Text>
                                            </Col>

                                            {/*<Col lg={24}>*/}
                                            {/*    <Typography.Text>*/}
                                            {/*        <Tag bordered={false}>Quantity:</Tag> {product.quantity} kit(s)*/}
                                            {/*    </Typography.Text>*/}
                                            {/*</Col>*/}

                                            <Col lg={24}>
                                                <NavLink to={`${product.url}`} target={'_blank'}>
                                                    <Button size={'small'} type={'link'} style={{color: "#01a9ac"}}>
                                                        Read about the product
                                                    </Button>
                                                </NavLink>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            })}

                            <Col lg={24}>
                                <Card bordered={false}>
                                    <Row>
                                        <Col lg={24}>
                                            {details.map((detail, index) => (
                                                <Typography.Paragraph key={index} style={{fontSize: 12}}>
                                                    {detail}
                                                </Typography.Paragraph>
                                            ))}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>


                    <Col>
                        <Space size={'large'}>
                            <OrderConfirmationModal
                                orderId={id}
                                productList={productList}
                                totalQuantity={totalQuantity}
                                minimumQuantity={minimumQuantity}
                            />

                            {/*<Button size={'large'} type={'dashed'}>*/}
                            {/*    Book a call*/}
                            {/*</Button>*/}
                        </Space>
                    </Col>
                </Row>


            </Card>
        </>
    }

    return <>
        <Card style={{marginBottom: 20, background: "rgba(113,202,243,0.03)"}}>
            <Row gutter={[24, 24]}>

                <Col lg={10}>
                    <Typography.Title level={4}>{title}</Typography.Title>
                </Col>

                <Col lg={14}>
                    <PromoTimer/>
                </Col>

                <Col lg={24}>
                    <Row gutter={[24, 24]}>
                        {productList.map(product => {
                            return <Col lg={8}>
                                <Card key={product.id} bordered={false}>
                                    <Row gutter={[0, 8]}>
                                        <Col lg={24}>
                                            <Typography.Text>
                                                <Tag bordered={false}>Product name:</Tag>
                                                <strong>{product.name}</strong>
                                            </Typography.Text>
                                        </Col>
                                        <Col lg={24}>
                                            <Typography.Text>
                                                <Tag bordered={false}>Quantity:</Tag> {product.quantity} kit(s)
                                            </Typography.Text>
                                        </Col>
                                        <Col lg={24}>
                                            <NavLink to={`${product.url}`} target={'_blank'}>
                                                <Button size={'small'} type={'link'} style={{color: "#01a9ac"}}>
                                                    Read about the product
                                                </Button>
                                            </NavLink>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        })}

                        <Col lg={24}>
                            <Card bordered={false}>
                                <Row>
                                    <Col lg={8}>
                                        <Typography.Paragraph>
                                            <Tag bordered={false}>Wholesale value</Tag> {wholesaleText}
                                        </Typography.Paragraph>

                                        <Typography.Paragraph>
                                            <Tag bordered={false}>Total Retail Sales:</Tag> {totalRentalSales}
                                        </Typography.Paragraph>
                                    </Col>

                                    <Col lg={8}>
                                        <Typography.Paragraph>
                                            <Tag bordered={false}>Investment:</Tag> {investmentText}
                                        </Typography.Paragraph>

                                        <Typography.Paragraph>
                                            <Tag bordered={false} color={'#01a9ac'}>Your Profit</Tag> {yourProfit}
                                        </Typography.Paragraph>
                                    </Col>

                                    <Col lg={8}>
                                        <Typography.Paragraph style={{fontSize: 12}}>{details[0]}</Typography.Paragraph>
                                        <Typography.Paragraph style={{fontSize: 12}}>{details[1]}</Typography.Paragraph>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>


                <Col>
                    <Space size={'large'}>

                        {/*<Button size={'large'} type={'primary'} onClick={id}>*/}
                        {/*    Buy now {id}*/}
                        {/*</Button>*/}


                        <OrderConfirmationModal
                            orderId={id}
                            productList={productList}
                            totalQuantity={totalQuantity}
                            minimumQuantity={minimumQuantity}
                            investmentText={investmentText}
                        />

                        {/*<Button size={'large'} type={'dashed'}>*/}
                        {/*    Book a call*/}
                        {/*</Button>*/}

                    </Space>
                </Col>
            </Row>


        </Card>
    </>
}

export default PromoOffer;